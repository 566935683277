<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">{{ title }}</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.dataDic"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="名称" prop="MC" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.MC"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="类型" prop="LX" :style="{ width: '80%' }">
                    <el-select
                        v-model="formdata.dataDic.LX"
                        :disabled="!isEdit"
                        placeholder="请选择"
                    >
                        <el-option
                            v-for="{ CODE, LABEL } in enumObj.LX"
                            :key="CODE"
                            :label="LABEL"
                            :value="CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="说明" prop="SM" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.SM"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="价值" prop="JZ" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.JZ"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="相关内容" :style="{ width: '80%' }">
                    <el-input
                        v-model="formdata.dataDic.XGNR"
                        :disabled="!isEdit"
                        placeholder="请填写"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item label="照片" prop="ZP" :style="{ width: '80%' }">
                    <basic-upload
                        savePath="monitor/tsdcylyct"
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="formdata.itemCode"
                        :metaData.sync="formdata.metaJson"
                        basicType="pictureSpecial"
                        @handleChange="handleChange"
                    ></basic-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import infoMixin from "../../0_com_js/info_mixin.js";
export default {
    mixins: [infoMixin],
    data() {
        return {
            BASE_URL: window.REQUEST_URL,
            title: "",
            isEdit: true,
            saveButton: true,
            formdata: {
                dataDic: {
                    glycbtid: this.heritageId,
                    MC: null, //名称
                    LX: null,
                    SM: null, //说明
                    JZ: null, //价值
                    XGNR: null, //相关内容
                },
                itemCode: "500201",
                metaJson: [],
            },
            fileList: [],
            rules: {
                MC: [
                    {
                        required: true,
                        message: "请填写名称",
                        trigger: ["blur"],
                    },
                ],
            },
        };
    },
    watch: {},
    mounted() {
        this.title = this.$route.meta.title;
    },
    methods: {},
};
</script>

<style></style>
